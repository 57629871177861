import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class ProductsPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  render() {
    let productData = this.props.data.allProductsJson.nodes[0]
    let products = []
    let i = 0
    productData.products.forEach(item => {
      products.push(
        <>
          <div key={i + '-image'} className="products-grid-item">
              <img src={`../images/produkter/${item.image}`} />
          </div>
          <div key={i + '-text'} className="products-grid-item">
            {item.name}
            <p style={{ fontWeight: "normal", fontSize: "17px"}}>{item.description}</p>
          </div>
        </>
      )
      i++
    })
    return (
      <Layout page={"products"}>
        <h1 className="title">{productData.title}</h1>
        <div className="text"><p>{productData.text1}</p><p>{productData.text2}</p></div>
        <div
          className={
            this.state.smallScreen
              ? "grid-container-small"
              : "products-grid-container"
          }
          style={{ textAlign: "center", width: "100%" }}
        >
          {products}
        </div>
      </Layout>
    )
  }
}
export default ProductsPage

export const query = graphql`
  query {
    allProductsJson {
      nodes {
        id
        title
        description
        text1
        text2
        products {
          name
          description
          image
        }
      }
    }
  }
`
